export const state = () => ({
  accessToken: '',
  member: null
})

export const mutations = {
  setAccessToken (state, accessToken) {
    state.accessToken = accessToken
  },
  setMember (state, member) {
    state.member = member
  }
}

export const actions = {
  async getMember ({ state, commit }) {
    await this.$axios.get(
      '/me',
      {
        headers: { Authorization: 'Bearer ' + state.accessToken }
      }
    ).then((res) => {
      if (res && res.data && !Array.isArray(res.data.data)) {
        commit('setMember', res.data.data)
      }
    })
  },
  async createMember ({ state, commit }, member) {
    await this.$axios.post(
      '/me',
      member,
      {
        headers: { Authorization: 'Bearer ' + state.accessToken }
      }
    ).then((res) => {
      if (res && res.data && !Array.isArray(res.data.data)) {
        commit('setMember', res.data.data)
      }
    })
  },
  async saveMember ({ state, commit }, member) {
    await this.$axios.put(
      '/me',
      member,
      {
        headers: { Authorization: 'Bearer ' + state.accessToken }
      }
    ).then((res) => {
      if (res && res.data && !Array.isArray(res.data.data)) {
        commit('setMember', res.data.data)
      }
    })
  }
}
