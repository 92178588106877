//
//
//

import { mapMutations, mapActions } from 'vuex'

export default {
  name: 'DefaultLayout',
  fetch () {
    this.$liffInit
      .then(() => {
        if (process.env.NODE_ENV !== 'development' && !this.$liff.isInClient()) {
          this.$router.replace('/unsupported')
          return
        }
        if (!this.$liff.isLoggedIn()) {
          this.$liff.login()
        } else if (!this.accessToken) {
          const accessToken = this.$liff.getAccessToken()
          this.setAccessToken(accessToken)
          this.getMember()
            .then(() => {
              const member = this.$store.state.member.member
              if (!member) {
                this.$router.replace('/register').catch(() => {})
              }
            })
            .catch(({ response }) => {
              // console.log('response', response)
              if (!response) {
                this.$nuxt.error({ message: '接続できません。' })
              } else {
                this.$nuxt.error({ statusCode: response.status, message: response.statusText })
              }
              // logout if external browser
              if (!this.$liff.isInClient()) {
                this.$liff.logout()
              }
            })
        }
      })
      .catch((e) => {
        console.log('layout, catch', e)
        this.$nuxt.error({ statusCode: 500, message: 'LIFFを初期化できません。' })
      })
  },
  methods: {
    ...mapMutations({
      setAccessToken: 'member/setAccessToken'
    }),
    ...mapActions({
      getMember: 'member/getMember'
    })
  }
}
