//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ErrorPage',
  props: {
    error: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    onClose () {
      console.log('onClose()')
      this.$liff.closeWindow()
    }
  }
}
